import React from 'react'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { POKER, POKER_STORIES } from '../../components/internal-links'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { generateBreadcrumbsSchema } from '../../lib/generate-schema'
import Stories from '../../components/Stories/stories'
import { pokerOneLinksPokerPage } from '../../components/one-links'
import {
  HOMEPAGE_BREADCRUMB_TITLE,
  POKER_BREADCRUMB_TITLE,
} from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: POKER_BREADCRUMB_TITLE,
    url: POKER,
  },
  {
    title: 'Web Stories',
    url: POKER_STORIES,
  },
]
const PokerWebStories: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      showPokerForm
      pokerOneLink={pokerOneLinksPokerPage}
    >
      <SEO
        title="Poker Web Stories: Interactive And Informative | Mega"
        description="Uncover the thrilling world of Poker through our collection of expertly crafted Poker web stories. Learn new tips & strategies to improve your game!"
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Stories
        title="Poker - Web Stories"
        prefix={`${POKER_STORIES}` || '/'}
        storyType="poker"
        displayFilters={false}
      />
    </Layout>
  )
}

export default PokerWebStories
